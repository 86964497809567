(function () {
	app.controller('CourseClassesCtrl', [
			"$scope", '$timeout', function ($scope, $timeout) {

				$scope.page = 1;
				$scope.page_size = '50';
				$scope.data = [];
				$scope.search = "";
				$scope.user_ids = "";

				$scope.init = function() {}

				$scope.previous = function() {
					if($scope.page == 1) return;
					$scope.page--;
					$scope.executeData();
			}

			$scope.next = function() {
					if($scope.page == $scope.total_pages) return;
					$scope.page++;
					$scope.executeData();
			}

			$scope.change_page = function(page) {
					if($scope.page == page) return;
					$scope.page = page;
					$scope.executeData();
			}

			$scope.getData = function() {
				$scope.page = 1;
				$scope.executeData();
		}

		$scope.executeData = function() {
				var params = {
						page: $scope.page,
						page_size: $scope.page_size,
						search: $scope.search
				}
				$scope.is_check_all = false;
				$scope.user_ids = "";
				$.get($scope.execute_data_path, params, function (rs) {
						$timeout(function() {
								if (rs && rs.succeed) {
										$scope.data = rs.data;
										$scope.total_pages = Math.ceil(rs.total / $scope.page_size)
								} else {
										$scope.data = [];
								}
						})
				});
		}
		}
]);
}).call(this);