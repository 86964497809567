(function () {
    app.controller('SubjectTestsCtrl', [
        "$scope", '$timeout', function ($scope, $timeout) {
            $scope.page = 1;
            $scope.page_size = '50';
            $scope.grade = '10';
            $scope.data = [];
            $scope.search = "";
            $scope.user_ids = "";
            $scope.is_check_all = false;
            
            $scope.init = function() {

            }

            $scope.getData = function() {
                $scope.page = 1;
                $scope.executeUsers();
            }

            $scope.executeUsers = function() {
                var params = {
                    page: $scope.page,
                    page_size: $scope.page_size,
                    grade: $scope.grade,
                    search: $scope.search
                }
                $scope.is_check_all = false;
                $scope.user_ids = "";
                $.get($scope.path, params, function (rs) {
                    $timeout(function() {
                        if (rs && rs.succeed) {
                            $scope.data = rs.data;
                            $scope.total_pages = Math.ceil(rs.total / $scope.page_size)
                        } else {
                            $scope.data = [];
                        }
                    })
                });
            }

            $scope.previous = function() {
                if($scope.page == 1) return;
                $scope.page--;
                $scope.executeUsers();
            }

            $scope.next = function() {
                if($scope.page == $scope.total_pages) return;
                $scope.page++;
                $scope.executeUsers();
            }

            $scope.change_page = function(page) {
                if($scope.page == page) return;
                $scope.page = page;
                $scope.executeUsers();
            }

            $scope.checkSubjectFunctionString = function(user) {
                    return user.g_subject + ', ' + user.thematic + ', ' + user.subject1 + ', ' + user.subject2;
            }

            $scope.checkSubjectFunction = function(user, arrayGSubjects, arrayThematics, arraySubject1,  arraySubject2) {
                if (arrayGSubjects.indexOf(user.g_subject) > -1)
                    // return user.g_subject;
                    return 'x';
                if (arrayThematics.indexOf(user.thematic) > -1)
                    return 'x';
                    // return user.thematic;
                if (arraySubject1.indexOf(user.subject1) > -1)
                    return 'x';
                    // return user.thematic;
                // if (arraySubject2.indexOf(user.subject2) > -1)
                //     return 'x';
                    // return user.thematic;
                return '';
            }
        }
	]);
}).call(this);